<template>
  <main :style="styleOverrides" class="page-wrapper">
    <update-checker></update-checker>
    <user-deleted-checker></user-deleted-checker>
    <navbar v-if="!shouldHideNavbar" :height-small="isOnMessaging" :hide-messaging-icon="isOnMessaging&&!isMobile"></navbar>
    <router-view/>
  </main>
  <!-- <page-footer></page-footer>  -->
</template>

<style lang="scss"></style>
<script>
import Navbar from "./components/Layout/Navbar"
import PageFooter from "./components/Layout/PageFooter"
import {mapGetters, mapActions} from 'vuex';
import axios from 'axios';
import UpdateChecker from "./components/UpdateChecker";
import UserDeletedChecker from "./components/UserDeletedChecker";

export default {
  components: {UpdateChecker, Navbar, PageFooter, UserDeletedChecker},
  data() {
    return {
      windowWidth: window.innerWidth
    }
  },
  setup() {

  },
  computed: {
    ...mapGetters('auth', {
      loggedIn: 'loggedIn',
      userRole: 'userRole'
    }),

    ...mapGetters('whitelabelConfiguration', {
      whitelabelConfiguration: 'whitelabelConfiguration'
    }),

    isOnMessaging() {
      return this.$route.name == 'Messaging';
    },

    isMobile() {
      return this.windowWidth < 1000;
    },

    darkBackgroundColour() {
      if (this.whitelabelConfiguration) {
        return this.whitelabelConfiguration.dark_background_colour;
      }
      return '31, 27, 45'
    },

    primaryColour() {
      if (this.whitelabelConfiguration) {
        return this.whitelabelConfiguration.primary_colour;
      }
      return '253, 86, 49'
    },

    styleOverrides() {
      return {
        '--bs-dark-rgb': this.darkBackgroundColour,
        '--primary-colour': this.primaryColour
      }
    },

    shouldHideNavbar() {
      return this.$route.name == 'Company Whitelabel Router';
    }
  },
  methods: {
    ...mapActions('whitelabelConfiguration', {
      configureWhitelabel: 'configureWhitelabel'
    }),

    ...mapActions('missionControl', {
      launch: 'launch'
    }),

    ...mapActions('favourites', {
      loadFavourites: 'loadFavourites'
    })
  },
  async mounted() {
    await this.$router.isReady();
    console.log(this.$route.query);

    // If is professionals.dnsassociates.co.uk, use wcid of 8
    if (window.location.hostname == 'professionals.dnsassociates.co.uk') {
      this.configureWhitelabel(8);
    } else {
      this.configureWhitelabel(this.$route.query.wcid); // whitelabel configuration id
    }
  },
  created() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });

    if (this.userRole) {
      this.launch();
      // Load favourites if staying on this user role
      // this.loadFavourites();
    }
    if (this.loggedIn) {
      axios.post(window.API_BASE + '/update-last-login').catch(e => {
        console.log(e);
      });
    }
  },
  watch: {}
}
</script>
<style lang="scss">
#app, .page-wrapper {
  height: 100%;
}

.text-primary, .breadcrumb-item.active, .nav-link.active, .nav-link:hover, .nav-link:focus  {
  color: rgb(var(--primary-colour)) !important;
}

a {
  // color: rgb(var(--primary-colour)) !important;
}

.btn-primary {
  background-color: rgb(var(--primary-colour)) !important;
  border-color: rgb(var(--primary-colour)) !important;
  color: #fff !important;
}

.btn-primary:hover {
  background-color: rgb(var(--primary-colour)) !important;
  border-color: rgb(var(--primary-colour)) !important;
  color: #fff !important;
}

.form-control:focus {
  border-color: rgb(var(--primary-colour)) !important;
  box-shadow: 0 0 0 0.25rem rgba(var(--primary-colour), 0.05) !important;
}
</style>